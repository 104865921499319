import TypographyUtils from "./TypographyUtils";

const genericStyles = {
  h1: `
    font-family: 'Barge', sans-serif;
    font-weight: bold;
    display: block;
    margin: 0;
    `,
  h2: `
    font-family: 'TeXGyreHeros', sans-serif;
    font-weight: bold;
    display: block;
    margin: 0;
    `,
  bold: `
    font-family: 'TeXGyreHeros', sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    display: block;
    margin: 0;
    `,
  regular: `
    font-family: 'TeXGyreHeros', sans-serif;
    font-weight: 400;
    display: block;
    margin: 0;
    `,
};

const modularScaleDesktop = {
  // 1.125 - major second
  // base 16
  heading1: {
    fontSize: TypographyUtils.convertPixelToEm(73.978),
    lineHeight: TypographyUtils.convertPixelToEm(83.225),
  },
  heading2: {
    fontSize: TypographyUtils.convertPixelToEm(41.053),
    lineHeight: TypographyUtils.convertPixelToEm(46.184),
  },
  cta: {
    fontSize: TypographyUtils.convertPixelToEm(18),
    lineHeight: TypographyUtils.convertPixelToEm(24),
  },
  body: {
    fontSize: TypographyUtils.convertPixelToEm(16),
    lineHeight: TypographyUtils.convertPixelToEm(24),
  },
};

const modularScaleMobile = {
  // 1.067 - minor second
  // base 14
  heading1: {
    fontSize: TypographyUtils.convertPixelToEm(48),
    lineHeight: TypographyUtils.convertPixelToEm(58.309),
  },
  heading2: {
    fontSize: TypographyUtils.convertPixelToEm(34.708),
    lineHeight: TypographyUtils.convertPixelToEm(42.16),
  },
  cta: {
    fontSize: TypographyUtils.convertPixelToEm(14),
    lineHeight: TypographyUtils.convertPixelToEm(18.146),
  },
  body: {
    fontSize: TypographyUtils.convertPixelToEm(12.297),
    lineHeight: TypographyUtils.convertPixelToEm(15.939),
  },
};

export default {
  genericStyles: genericStyles,
  heading1: `
  ${genericStyles.h1}
  ${TypographyUtils.fontSizeCalc(
    modularScaleDesktop.heading1.fontSize,
    modularScaleMobile.heading1.fontSize
  )};
  
  ${TypographyUtils.lineHeightCalc(
    modularScaleDesktop.heading1.lineHeight,
    modularScaleMobile.heading1.lineHeight
  )};
  `,

  heading2: `
  ${genericStyles.h2}
  ${TypographyUtils.fontSizeCalc(
    modularScaleDesktop.heading2.fontSize,
    modularScaleMobile.heading2.fontSize
  )};
  
  ${TypographyUtils.lineHeightCalc(
    modularScaleDesktop.heading2.lineHeight,
    modularScaleMobile.heading2.lineHeight
  )};
  `,

  cta: `
  ${genericStyles.bold}
  ${TypographyUtils.fontSizeCalc(
    modularScaleDesktop.cta.fontSize,
    modularScaleMobile.cta.fontSize
  )};
  
  ${TypographyUtils.lineHeightCalc(
    modularScaleDesktop.cta.lineHeight,
    modularScaleMobile.cta.lineHeight
  )};
  `,

  body: `
  ${genericStyles.regular}
  ${TypographyUtils.fontSizeCalc(
    modularScaleDesktop.body.fontSize,
    modularScaleMobile.body.fontSize
  )};
  
  ${TypographyUtils.lineHeightCalc(
    modularScaleDesktop.body.lineHeight,
    modularScaleMobile.body.lineHeight
  )};
  `,
};
