import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Heading3 } from "../text-elements/index";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";

const BuyButton = ({ hovered, children, controlHoverColour }) => {
  return (
    <Wrapper hovered={hovered} controlHoverColour={controlHoverColour}>
      <Text>{children}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 1px white solid;
  padding: 2rem 3rem;
  transform: scale(1, 1);
  transition: all 0.4s ease-out;

  @media ${Devices.tablet} {
    transform: ${(props) =>
      props.hovered ? "scale(1.03, 1.03)" : "transform: scale(1, 1)"};

    ${(props) =>
      props.controlHoverColour && props.hovered
        ? `background-color: ${Colors.hover}`
        : ``}
  }

  ${(props) =>
    props.controlHoverColour ? `background-color: ${Colors.black}` : ``}
`;

const Text = styled(Heading3)`
  text-align: center;
`;

export default BuyButton;
