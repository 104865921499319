import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import LogoImg from "../../assets/logo.png";
import VerticalArtwork from "../../assets/vertical-artwork.jpg";
import HorizontalArtwork from "../../assets/horizontal-artwork.jpg";
import VerticalArtworkRed from "../../assets/vertical-artwork-red.png";
import Devices from "../../consts/devices/Devices";

const Logo = ({}) => {
  return (
    <>
      <Image
        src={VerticalArtworkRed}
        alt="do you listen to girl in red?"
        title="do you listen to girl in red?"
      />

      <VisuallyHiddenH1>do you listen to girl in red?</VisuallyHiddenH1>
    </>
  );
};

const Image = styled.img`
  border-radius: 0.5rem;
  height: 40vh;
  /* height: 20vh; */
  width: auto;

  @media ${Devices.tablet} {
    height: 45vh;
  }
`;

const VisuallyHiddenH1 = styled.h1`
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
`;

export default Logo;
