import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import Charity from "../../components/charity/Charity";
import Logo from "../../components/logo/Logo";
import HomeCta from "../../components/home-cta/HomeCta";
import { Heading2 } from "../../components/text-elements/index";
import HomeHeading from "../../components/home-heading/HomeHeading";
import Devices from "../../consts/devices/Devices";
import MerchItem from "../../components/merch-item/MerchItem";
import DownloadItem from "../../components/download-item/DownloadItem";
import Colors from "../../consts/Colors";

const Main = ({ charities, merch, loaded, loadedAnimationTimeout }) => {
  return (
    <Wrapper loaded={loaded} loadedAnimationTimeout={loadedAnimationTimeout}>
      <Inner>
        <LogoBar>
          <Logo />
          {/* <HomeHeading type="primary">DO YOU LISTEN TO GIRL IN RED?</HomeHeading> */}
          {/* to trigger deploy */}
        </LogoBar>
        <LogoGrid />
        <HomeCtaItemWrapper position={2}>
          <HomeHeading icon={"♩"}>LISTEN TO GIRL IN RED</HomeHeading>
          <CtaWrapper num={1}>
            <HomeCta type="listen" />
          </CtaWrapper>
          <MiniCentredLineOfPerception />
        </HomeCtaItemWrapper>
        <HomeCtaItemWrapper position={3} fullPadding>
          <HomeHeading icon={"♩"}>DOWNLOAD GIRL IN RED</HomeHeading>
          <DownloadItem />
        </HomeCtaItemWrapper>
        <SecondSectionWrapper position={4}>
          <CentredLineOfPerception />
          <CharitiesWrapper>
            <HomeHeading icon={"☺︎"}>DONATE WITH GIRL IN RED</HomeHeading>
            <CharityItemsWrapper>
              {charities &&
                charities.map((prismicItem, i) => {
                  return <Charity key={i} prismicItem={prismicItem}></Charity>;
                })}
            </CharityItemsWrapper>
          </CharitiesWrapper>

          <ShopWrapper>
            <HomeHeading icon={"♾"}> SHOP GIRL IN RED</HomeHeading>
            <MerchItemsWrapper>
              {merch &&
                merch
                  .map((prismicItem, i) => {
                    return (
                      <MerchItem key={i} prismicItem={prismicItem}></MerchItem>
                    );
                  })
                  .reverse()}
            </MerchItemsWrapper>
          </ShopWrapper>
        </SecondSectionWrapper>
        <HomeCtaItemWrapper position={5}>
          <HomeHeading icon={"☼"}> VISIT WORLD IN RED</HomeHeading>
          <CtaWrapper num={3}>
            <HomeCta type="visit" />
          </CtaWrapper>
        </HomeCtaItemWrapper>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${(props) =>
    !props.loadedAnimationTimeout ? "display: none;" : "padding-bottom: 8rem;"};
`;

const Inner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 1.5rem;
  grid-column-gap: 0.5rem;
  max-width: 1080px;
  margin: 0 auto;
  padding: 1rem;
  @media ${Devices.mobileL} {
    padding: 1.5rem;
    grid-row-gap: 2rem;
    grid-column-gap: 1.5rem;
  }

  @media ${Devices.tablet} {
    padding: 2rem;
    grid-row-gap: 4rem;
    grid-column-gap: 4rem;
  }
`;

const HomeCtaItemWrapper = styled.div`
  position: relative;
  z-index: 0;
  grid-row: ${(props) => props.position};
  grid-column: 1/3;
  ${(props) => (props.fullPadding ? "padding: 8rem 0 0;" : "")}
`;

const CentredLineOfPerception = styled.div`
  z-index: -1;
  position: absolute;
  width: 1px;
  background-color: white;
  left: 50%;
  height: calc(100% + 3rem);
  top: -1.5rem;

  @media ${Devices.mobileL} {
    height: calc(100% + 4rem);
    top: -2rem;
  }

  @media ${Devices.tablet} {
    height: calc(100% + 8rem);
    top: -4rem;
  }
`;

const MiniCentredLineOfPerception = styled.div`
  z-index: -1;
  position: absolute;
  width: 1px;
  background-color: white;
  left: 50%;
  height: 12rem;
  bottom: -12rem;
`;

const LogoBar = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;

  @media ${Devices.tablet} {
    padding-top: 3rem;
  }

  animation: float 6s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translatey(0px) translateX(-50%);
    }
    50% {
      transform: translatey(-20px) translateX(-50%);
    }
    100% {
      transform: translatey(0px) translateX(-50%);
    }
  }
`;

const LogoGrid = styled.div`
  height: 40vh;
  width: auto;

  @media ${Devices.tablet} {
    height: 45vh;
  }

  grid-row: 1;
  grid-column: 1/3;
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/3;
  grid-row: ${(props) => props.num + 1};
  padding: 0 1rem;

  @media ${Devices.mobileL} {
    padding: 0 1.5rem;
  }

  @media ${Devices.tablet} {
    padding: 0 3rem;
  }
`;

const SecondSectionWrapper = styled.div`
  z-index: 0;
  position: relative;
  display: grid;
  grid-row: ${(props) => props.position};
  grid-column: 1/3;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 1.5rem;
  grid-column-gap: 0.5rem;
`;

const CharitiesWrapper = styled.div`
  z-index: 1;
  position: relative;
  padding: 8rem 0 0;
  grid-row: 1;
  grid-column: 1/3;
`;

const CharityItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;

  @media ${Devices.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`;

const ShopWrapper = styled.div`
  z-index: 1;
  position: relative;
  padding: 6rem 0 0;
  grid-row: 2;
  grid-column: 1/3;
`;

const MerchItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;

  @media ${Devices.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`;

export default Main;
