import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Body } from "../text-elements";
import BuyButton from "../buy-button/BuyButton";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";
import VerticalArtwork from "../../assets/vertical-artwork.jpg";

const MerchItem = ({}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Wrapper>
      <Inner
      // hovered={hovered}
      >
        <a
          href="/poster.jpg"
          title="Download 'do you listen to girl in red' poster"
          download="do-you-listen-to-girl-in-red.jpg"
        >
          <ImageWrapper
            hovered={hovered}
            onMouseOver={() => {
              setHovered(true);
            }}
            onMouseOut={() => {
              setHovered(false);
            }}
          >
            <ImageOverlay hovered={hovered} />
            <Image
              src={VerticalArtwork}
              alt="do you listen to girl in red poster"
              title="Download 'do you listen to girl in red' poster"
            />
          </ImageWrapper>
        </a>
        <a
          href="/poster.jpg"
          title="download do you listen to girl in red poster"
          download="do-you-listen-to-girl-in-red.jpg"
          onMouseOver={() => {
            setHovered(true);
          }}
          onMouseOut={() => {
            setHovered(false);
          }}
        >
          <ButtonMargin />
          <BuyButton hovered={hovered} controlHoverColour>
            DOWNLOAD POSTER
          </BuyButton>
        </a>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
  /* margin-top: 1.5rem; */
  padding: 0rem 1rem;
  background-color: ${Colors.black};

  @media ${Devices.mobileL} {
    padding: 0rem 1.5rem;
  }

  @media ${Devices.tablet} {
    transition: opacity 0.4s ease-out;
    padding: 0 4rem 3rem;
    /* margin-top: 3rem; */
    opacity: ${(props) => (props.hovered ? 0.8 : 1)};
  }
`;

const Inner = styled.div`
  padding: 0 2rem;
  border-radius: 1rem;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.black};
`;

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.4s ease-out;
  background-color: ${Colors.black};

  @media ${Devices.tablet} {
    background-color: ${(props) =>
      props.hovered ? "rgba(255, 255, 255, 0.08)" : Colors.black};
  }
`;

const ImageOverlay = styled.div`
  /* background-color: rgba(0, 0, 0, 0); */
  transition: all 0.4s ease-out;
  z-index: 40;
  position: absolute;
  top: 0;
  left: 0;

  @media ${Devices.tablet} {
    background-color: ${(props) =>
      props.hovered ? "rgba(255, 255, 255, 0.08)" : "rgba(255, 255, 255, 0)"};
  }
  height: 100%;
  width: 100%;
`;

const Image = styled.img`
  border-radius: 4px;
  width: auto;
  object-fit: contain;
  height: 300px;

  @media ${Devices.tablet} {
    height: 400px;
  }
`;

const ButtonMargin = styled.div`
  padding-bottom: 1.5rem;
`;

export default MerchItem;
