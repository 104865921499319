import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import Devices from "../../consts/devices/Devices";

const CharitySocials = ({ twitter, facebook, instagram }) => {
  return (
    <Wrapper>
      <SocialInnerWrapper>
        <a href={instagram} target="blank">
          <Instagram alt="instagram" title="visit Instagram" />
        </a>
      </SocialInnerWrapper>
      <SocialInnerWrapper>
        <a href={facebook} target="blank">
          <Facebook alt="facebook" title="visit Facebook" />
        </a>
      </SocialInnerWrapper>
      <SocialInnerWrapper>
        <a href={twitter} target="blank">
          <Twitter alt="twitter" title="visit Twitter" />
        </a>
      </SocialInnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`;

const SocialInnerWrapper = styled.div`
  margin: 0 0.4rem;

  svg {
    width: auto;
    height: 16px;
    path {
      fill: white;
    }

    @media ${Devices.tablet} {
      height: 18px;
    }
  }
`;

export default CharitySocials;
