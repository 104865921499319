import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Colors from "../consts/Colors";
import SplashScreen from "./splash-screen/SplashScreen";
import MainScreen from "./main-screen/MainScreen";
import GetInitialData from "../utilities/get-initial-data/GetInitialData";

const App = () => {
  const [charities, setCharities] = useState(null);
  const [merch, setMerch] = useState(null);
  const [loadedAnimationTimeout, setLoadedAnimationTimeout] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const totalLoadTime = 7 * 400 + 400 + 1400;

  useEffect(() => {
    async function apiCall() {
      const { charities, merch } = await GetInitialData();
      setCharities(charities);
      setMerch(merch);
    }

    apiCall();

    setTimeout(() => {
      setLoadedAnimationTimeout(true);
    }, totalLoadTime);

    setTimeout(() => {
      setLoaded(true);
    }, totalLoadTime + 1000);
  }, []);

  return (
    <AppWrapper>
      <SplashScreen
        loaded={loaded}
        loadedAnimationTimeout={loadedAnimationTimeout}
      />
      <MainScreen
        loaded={loaded}
        loadedAnimationTimeout={loadedAnimationTimeout}
        // loaded={true}
        // loadedAnimationTimeout={true}
        charities={charities}
        merch={merch}
      />
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  background-color: ${Colors.black};
`;

export default App;
