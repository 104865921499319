import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Body } from "../text-elements";
import BuyButton from "../buy-button/BuyButton";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";

const MerchItem = ({ prismicItem }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Wrapper>
      <a
        href={prismicItem.data.link.url}
        target="_blank"
        title={`Buy '${prismicItem.data.image.alt}'`}
      >
        <Inner
          hovered={hovered}
          onMouseOver={() => {
            setHovered(true);
          }}
          onMouseOut={() => {
            setHovered(false);
          }}
        >
          <ImageWrapper>
            <Image
              src={prismicItem.data.image.url}
              alt={prismicItem.data.image.alt}
              title={prismicItem.data.image.alt}
            />
          </ImageWrapper>
          <Text>{prismicItem.data.name.toUpperCase()}</Text>
          <Price>{prismicItem.data.price}</Price>
          <BuyButton hovered={hovered}>BUY</BuyButton>
        </Inner>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1rem 0;
  background-color: ${Colors.black};

  @media ${Devices.mobileL} {
    padding: 1.5rem 0;
  }

  @media ${Devices.tablet} {
    padding: 0 4rem 3rem;
    margin-top: 3rem;
    background-color: rgba(255, 255, 255, 0);
  }
`;

const Inner = styled.div`
  padding: 2rem 1rem;
  border-radius: 1rem;
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease-out;
  background-color: ${Colors.black};

  @media ${Devices.tablet} {
    padding: 2rem;
    background-color: ${(props) =>
      props.hovered ? "rgba(255, 255, 255, 0.08)" : Colors.black};
  }
`;

const ImageWrapper = styled.div`
  /* padding: 0 2rem; */
`;

const Image = styled.img`
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
  height: 300px;

  @media ${Devices.tablet} {
    height: 400px;
  }
`;

const Text = styled(Body)`
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Price = styled(Body)`
  text-align: center;
  margin-bottom: 1.5rem;
`;

export default MerchItem;
