import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Heading3 } from "../text-elements/index";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";
import { ReactComponent as ListenIcon } from "../../assets/triangle.svg";
import { ReactComponent as WorldIcon } from "../../assets/circle.svg";
import GifHover from "../gif-hover/GifHover";

const HomeCta = ({ type }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <a
      href={
        type === "listen"
          ? "https://gir.lnk.to/musicKT"
          : "https://worldinred.com/"
      }
      title={type === "listen" ? "Listen to girl in red" : "Visit world in red"}
      id={type === "listen" ? "music_BTN" : "website_BTN"}
      target="blank"
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseOut={() => {
        setHovered(false);
      }}
    >
      <Wrapper>
        <Text>{type === "listen" ? "LISTEN" : "ENTER"}</Text>
        <GifHover type={type} hovered={hovered} />
      </Wrapper>
    </a>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 1px white solid;
  padding: 2rem 3rem;
  transform: scale(1, 1);
  transition: all 0.4s ease-out;
  background-color: ${Colors.black};
  z-index: 4;

  svg {
    height: 2rem;
    width: 100%;
    circle,
    path {
      transition: all 0.4s ease-out;
      stroke-width: 5px;
      stroke: white;
      fill: ${Colors.black};
    }
  }

  @media ${Devices.mobileL} {
    width: 300px;
  }

  @media ${Devices.tablet} {
    width: 350px;

    &:hover {
      transform: scale(1.02, 1.02);
      background-color: rgba(255, 255, 255, 0.08);

      svg {
        circle,
        path {
          stroke-width: 5px;
          stroke: ${Colors.red};
          fill: ${Colors.red};
        }
      }
    }
  }
`;

const Text = styled(Heading3)`
  text-align: center;
  margin-bottom: 1rem;
`;

export default HomeCta;
