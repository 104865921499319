import Prismic from 'prismic-javascript';

const GetInitialData = async () => {
  const apiEndpoint = 'https://dyltgir.cdn.prismic.io/api/v2';
  const accessToken = '';
  const Client = Prismic.client(apiEndpoint, { accessToken });

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.any('document.type', ['charity', 'merch_item'])
    );
    if (response) {
      return {
        charities: response.results.filter((result) => result.type === 'charity'),
        merch: response.results.filter((result) => result.type === 'merch_item')
      };
    }
  };
  const data = fetchData();
  return data;
};

export default GetInitialData;
