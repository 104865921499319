import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Body, Cta } from "../text-elements/index";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";
import WorldGif from "../../assets/world-anim.gif";
import WorldStill from "../../assets/world-still.png";
import GuitarGif from "../../assets/guitar-anim.gif";
import GuitarStill from "../../assets/guitar-still.png";

const GifHover = ({ hovered, type }) => {
  return (
    <Wrapper>
      <ImageOverlay
        src={type === "listen" ? GuitarStill : WorldStill}
        hovered={hovered}
        type={type}
        role="img"
        aria-label={
          type === "listen"
            ? "do you listen to girl in red guitar still"
            : "do you listen to girl in red world still"
        }
      />
      <Gif
        src={type === "listen" ? GuitarGif : WorldGif}
        hovered={hovered}
        type={type}
        alt={
          type === "listen"
            ? "do you listen to girl in red guitar gif"
            : "do you listen to girl in red world gif"
        }
        title={
          type === "listen"
            ? "do you listen to girl in red guitar gif"
            : "do you listen to girl in red world gif"
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 6rem;
  width: 6rem;
  /* height: ${(props) =>
    props.type === "listen" ? "6rem" : "4rem"};
  width: ${(props) =>
    props.type === "listen" ? "6rem" : "4rem"}; */
`;

const Gif = styled.img`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: ${(props) => (props.type === "listen" ? "6rem" : "4rem")};
  width: ${(props) => (props.type === "listen" ? "6rem" : "4rem")};
  opacity: 1;

  @media ${Devices.tablet} {
    opacity: ${(props) => (props.hovered ? 1 : 0)};
    transition: all 0.3s ease-out;
  }
`;

const ImageOverlay = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: ${(props) => (props.type === "listen" ? "6rem" : "4rem")};
  width: ${(props) => (props.type === "listen" ? "6rem" : "4rem")};
  background: url(${(props) => props.src});
  opacity: 0;
  transition: all 0.3s ease-out;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  @media ${Devices.tablet} {
    opacity: ${(props) => (props.hovered ? 0 : 0.9)};
    transition: all 0.3s ease-out;
  }
`;

export default GifHover;
