import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Heading2, Cta } from "../text-elements/index";
import Colors from "../../consts/Colors";

const HomeHeading = ({ children, type, icon }) => {
  return (
    <Wrapper>
      {/* {icon ? <IconText>{icon}</IconText> : null} */}
      <Text type={type}>{children}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconText = styled(Heading2)`
  display: inline;
  text-align: center;
  color: white;
`;

const Text = styled(Heading2)`
  /* font-weight: normal; */
  display: inline;
  text-align: center;
  background-color: ${Colors.black};
  padding: 1.5rem;
  border-radius: 4px;
  color: ${(props) => (props.type === "primary" ? Colors.red : "white")};
  /* margin-bottom: 0.5rem; */
`;

export default HomeHeading;
