import React from 'react';
import styled from 'styled-components';
import Typography from '../../../consts/typography/Typography';
import Colors from '../../../consts/Colors';

const Heading1 = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

const Wrapper = styled.h1`
  font-family: 'Barge', sans-serif;
  font-weight: bold;
  display: block;
  margin: 0;
  font-size: 35vw;
  color: ${Colors.black};
`;

export default Heading1;
