import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import Colors from "../../consts/Colors";
import { Heading1 } from "../../components/text-elements/index";
import SplashText from "../../components/splash-text/SplashText";
import { tint } from "polished";

const SplashScreen = ({ loadedAnimationTimeout, loaded }) => {
  const [counter, setCounter] = useState(-1);
  const wordsArray = [
    "",
    "DO",
    "YOU",
    "LISTEN",
    "TO",
    "GIRL",
    "IN",
    "RED",
    "?",
  ];
  const lighterTint = tint(0.6, Colors.red);
  let colours = `${Colors.red}, ${lighterTint}`;

  useEffect(() => {
    if (!loaded && counter <= 9) {
      setTimeout(() => {
        const newCount = counter + 1;
        setCounter(newCount);
      }, setTimer(counter));
    }
  }, [counter, loaded]);
  // Total time = 7 x 400 + 400 + 1200;

  const setTimer = (counter) => {
    switch (counter) {
      case 2:
        return 400;

      case 8:
        return 1600;

      default:
        return 400;
    }
  };

  return (
    <Wrapper loadedAnimationTimeout={loadedAnimationTimeout} loaded={loaded}>
      {wordsArray.map((word, index) => {
        return (
          <SplashText key={index} visible={counter === index}>
            {word}
          </SplashText>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  pointer-events: none;
  position: absolute;
  opacity: ${(props) => (props.loadedAnimationTimeout ? 0 : 1)};
  display: ${(props) => (props.loaded ? "none" : "flex")};
  transition: opacity, 0.6s ease-out;
  z-index: 5;
  min-height: 100vh;
  width: 100vw;
  background-color: ${Colors.red};
  overflow-x: hidden;
  overflow-y: hidden;
`;

export default SplashScreen;
