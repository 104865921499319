import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Body } from "../text-elements";
import Socials from "../charity-socials/CharitySocials";
import DonateNowButton from "../donate-now-button/DonateNowButton";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";

const Charity = ({ prismicItem }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Wrapper>
      <Name>{prismicItem.data.name.toUpperCase()}</Name>
      <a
        href={prismicItem.data.donate_link.url}
        title={
          prismicItem.data.name.toUpperCase() ===
          "THE MARSHA P. JOHNSON INSTITUTE"
            ? "Donate to the Marsha P. Johnson Institute"
            : "Donate to the Trevor Project"
        }
        target="_blank"
        id={
          prismicItem.data.name.toUpperCase() ===
          "THE MARSHA P. JOHNSON INSTITUTE"
            ? "MarthaPJohnson_BTN"
            : "trevorproject_BTN"
        }
      >
        <ImageWrapper
          onMouseOver={() => {
            setHovered(true);
          }}
          onMouseOut={() => {
            setHovered(false);
          }}
          hovered={hovered}
          src={prismicItem.data.image.url}
          role="img"
          aria-label={prismicItem.data.image.alt}
        >
          <ImageOverlay hovered={hovered} />
          <ButttonWrapper>
            <DonateNowButton hovered={hovered} />
          </ButttonWrapper>
        </ImageWrapper>
      </a>
      <Description>{prismicItem.data.description}</Description>
      <Socials
        twitter={prismicItem.data.twitter.url}
        facebook={prismicItem.data.facebook.url}
        instagram={prismicItem.data.instagram.url}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* border-left: 1px solid white;
  border-right: 1px solid white; */
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: ${Colors.black};

  @media ${Devices.mobileL} {
    padding: 1.5rem;
  }

  @media ${Devices.tablet} {
    padding: 0 4rem 3rem;
    margin-top: 3rem;
    background-color: rgba(255, 255, 255, 0);
  }
`;

const Name = styled(Body)`
  text-align: center;
  margin-bottom: 1rem;
`;

const ImageWrapper = styled.div`
  cursor: pointer;
  position: relative;
  margin-bottom: 1rem;
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  color: #ffffff;
  height: 300px;

  @media ${Devices.tablet} {
    height: 400px;
  }
`;

const ImageOverlay = styled.div`
  /* background-color: rgba(0, 0, 0, 0); */
  transition: all 0.4s ease-out;

  @media ${Devices.tablet} {
    background-color: ${(props) =>
      props.hovered ? "rgba(255, 255, 255, 0.08)" : "rgba(255, 255, 255, 0)"};
  }
  height: 100%;
  width: 100%;
`;

const ButttonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Description = styled(Body)`
  text-align: center;
  padding: 0.5rem;

  @media ${Devices.tablet} {
    padding: 1rem;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 1rem 0;
`;

export default Charity;
