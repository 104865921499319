import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Heading3 } from "../text-elements/index";
import Devices from "../../consts/devices/Devices";
import Colors from "../../consts/Colors";

const DonateNowButton = ({ src, hovered }) => {
  return (
    <Wrapper hovered={hovered}>
      <Text>DONATE</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 1px white solid;
  padding: 2rem 3rem;
  transform: scale(1, 1);
  transition: all 0.4s ease-out;
  background-color: rgba(0, 0, 0, 0.35);

  @media ${Devices.tablet} {
    transform: ${(props) =>
      props.hovered ? "scale(1.03, 1.03)" : "transform: scale(1, 1)"};
    background-color: ${(props) =>
      props.hovered ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.35)"};
  }
`;

const Text = styled(Heading3)`
  text-align: center;
`;

export default DonateNowButton;
