import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Heading1 } from "../text-elements/index";
import Colors from "../../consts/Colors";

const SplashText = ({ children, visible }) => {
  return (
    <Wrapper>
      <MessageText visible={visible}>{children}</MessageText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const MessageText = styled(Heading1)`
  color: ${Colors.white};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  /* margin-top: ${(props) => (props.visible ? "10%" : `50%`)}; */
  /* transition: opacity, margin-top, 0.1s ease-out; */
`;

export default SplashText;
